import {useCallback, useMemo} from 'react'

import {FormProvider, useForm as useHookForm} from 'react-hook-form'
import * as yup from 'yup'

import Form from 'components/common/Form/components/Form'
import useYupValidationResolver from 'components/common/Form/hooks/useYupValidationResolver'

export function getFieldProps(hookFormProps, name) {
  if (!hookFormProps) {
    return
  }

  return {
    register: hookFormProps.register,
    control: hookFormProps.control,
    fieldValue: hookFormProps.getValues(name),
    setFieldValue: val => hookFormProps.setValue(name, val),
    error: name.split('.').reduce((acc, curr) => acc && acc[curr], hookFormProps.errors),
    isTouched: name
      .split('.')
      .reduce((acc, curr) => acc && acc[curr], hookFormProps.formState.touched)
  }
}

function FormWithProvider({children}) {
  return (
    <FormProvider>
      <Form>{children}</Form>
    </FormProvider>
  )
}

function useForm(options = {}) {
  let resolver = options.resolver
  if (!resolver && options.validation) {
    const validationObj = useMemo(() => options.validation, [])
    resolver = useYupValidationResolver(yup.object(validationObj))
  }

  const formMethods = useHookForm({mode: 'all', ...options, resolver})
  const registerField = useCallback(name => ({...getFieldProps(formMethods, name), name}), [
    formMethods
  ])

  Form.defaultProps = {
    ...formMethods,
    getFieldProps,
    onSubmit: options.onSubmit
  }

  FormProvider.defaultProps = {
    ...formMethods
  }

  return {Form, getFieldProps, FormProvider, FormWithProvider, registerField, ...formMethods}
}

export default useForm
