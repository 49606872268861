import React from 'react'

import * as S from './Styled'

const Form = React.forwardRef(
  ({spacing = {base: 0}, children, onSubmit, getFieldProps, handleSubmit, ...rest}, ref) => {
    const formattedSpacing = typeof spacing === 'object' ? spacing : {base: spacing}

    return (
      <S.Form ref={ref} spacing={formattedSpacing} onSubmit={handleSubmit(onSubmit)}>
        {React.Children.map(children, child => {
          return child && child.props && child.props.name
            ? React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...getFieldProps(rest, child.props.name)
                }
              })
            : child
        })}
      </S.Form>
    )
  }
)

export default Form
