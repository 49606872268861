import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: ${props => props.spacing.base}px;
  }

  ${props => props.theme.mediaQuery.xs} {
    ${props =>
      typeof props.spacing.xs !== 'undefined'
        ? `
    > *:not(:last-child) {
      margin-bottom: ${props.spacing.xs}px;
    }
    `
        : null}
  }

  ${props => props.theme.mediaQuery.sm} {
    ${props =>
      typeof props.spacing.sm !== 'undefined'
        ? `
    > *:not(:last-child) {
      margin-bottom: ${props.spacing.sm}px;
    }
    `
        : null}
  }

  ${props => props.theme.mediaQuery.md} {
    ${props =>
      typeof props.spacing.md !== 'undefined'
        ? `
    > *:not(:last-child) {
      margin-bottom: ${props.spacing.md}px;
    }
    `
        : null}
  }

  ${props => props.theme.mediaQuery.lg} {
    ${props =>
      typeof props.spacing.lg !== 'undefined'
        ? `
    > *:not(:last-child) {
      margin-bottom: ${props.spacing.lg}px;
    }
    `
        : null}
  }

  ${props => props.theme.mediaQuery.xl} {
    ${props =>
      typeof props.spacing.xl !== 'undefined'
        ? `
    > *:not(:last-child) {
      margin-bottom: ${props.spacing.xl}px;
    }
    `
        : null}
  }

  ${props => props.theme.mediaQuery.xxl} {
    ${props =>
      typeof props.spacing.xxl !== 'undefined'
        ? `
    > *:not(:last-child) {
      margin-bottom: ${props.spacing.xxl}px;
    }
    `
        : null}
  }
`
